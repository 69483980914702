<template>
  <v-form>
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            v-model="item.nom"
            :error-messages="nomErrors"
            :label="$t('nom')"
            @input="$v.item.nom.$touch()"
            @blur="$v.item.nom.$touch()"
          />
        </v-col>
      
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            v-model.number="item.priorite"
            :error-messages="prioriteErrors"
            :label="$t('priorite')"
            @input="$v.item.priorite.$touch()"
            @blur="$v.item.priorite.$touch()"
          />
        </v-col>
      </v-row>
      
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <v-combobox
            v-model="item.partenaires"
            :items="partenairesSelectItems"
            :error-messages="partenairesErrors"
            :no-data-text="$t('No results')"
            :label="$t('partenaires')"
            multiple
            item-text="name"
            item-value="@id"
            chips
          />
        </v-col>
      
        <v-col cols="12"></v-col>
      </v-row>
      
    </v-container>
  </v-form>
</template>

<script>
import has from 'lodash/has';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'CategoriePartenaireForm',
  mixins: [validationMixin],
  props: {
    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    }
  },
  mounted() {
      this.partenairesGetSelectItems();
  },
  data() {
    return {
        nom: null,
        priorite: null,
        partenaires: null,
    };
  },
  computed: {
      ...mapFields('partenaire', {
        partenairesSelectItems: 'selectItems'
      }),

    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },

    nomErrors() {
      const errors = [];

      if (!this.$v.item.nom.$dirty) return errors;

      has(this.violations, 'nom') && errors.push(this.violations.nom);


      return errors;
    },
    prioriteErrors() {
      const errors = [];

      if (!this.$v.item.priorite.$dirty) return errors;

      has(this.violations, 'priorite') && errors.push(this.violations.priorite);


      return errors;
    },
    partenairesErrors() {
      const errors = [];

      if (!this.$v.item.partenaires.$dirty) return errors;

      has(this.violations, 'partenaires') && errors.push(this.violations.partenaires);


      return errors;
    },

    violations() {
      return this.errors || {};
    }
  },
  methods: {
      ...mapActions({
        partenairesGetSelectItems: 'partenaire/fetchSelectItems'
      }),
  },
  validations: {
    item: {
      nom: {
      },
      priorite: {
      },
      partenaires: {
      },
    }
  }
};
</script>
